import styledComponents from 'styled-components';

const RoundedTitle = styledComponents.div`
    background: 
    linear-gradient(${({ theme }) => theme.colors.typography.black}, ${({ theme }) =>
  theme.colors.typography.black}) padding-box,
    ${({ theme }) => theme.colors.button.border} border-box;
    border-radius: 100px;
    border: 6px solid transparent;
    padding: 2rem 4rem;
    text-align: center;
    width: 100%;
    cursor: pointer;

    > h2 {
        font-size: 4rem;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.common.white};

        > strong {
          color: ${({ theme }) => theme.colors.common.primary};
        }
    }

    @media only screen and (max-width: 768px) {
      padding: 2rem;
      border-radius: 50px;

      > h2 {
        font-size: 2.5rem;
      }
    }
`;

export default RoundedTitle;
