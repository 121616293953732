import React from 'react';
import styledComponents from 'styled-components';
import { EXTERNAL_FORM_LINK, sectionIds } from '../constants/constants';
import Description from './shared/Description';
import Section from './shared/Section';
import SectionTitle from './shared/SectionTitle';
import { v4 as uuidv4 } from 'uuid';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import RoundedTitle from './shared/RoundedTitle';

const SectionContainer = styledComponents(Section)`
  margin-bottom: 10.75rem;
`;

const Content = styledComponents.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.5rem;
  max-width: 54.5rem;
`;

const CtaContainer = styledComponents.div`
  margin-top: 5rem;
`;

const ContentItems = [
  {
    description:
      'IAMN DAO will continue to work toward enabling collaboration among professional athletes, launching token events, promoting networking, facilitating new investment opportunities and strategies, growing the community, and finding new ways to drive change.'
  },
  {
    description:
      'Connecting pro athletes worldwide is our goal. IAMN will reinvent how pro athletes communicate and collaborate to benefit our members and their communities.'
  }
];

const Future = () => {
  const openForm = () => {
    window.open(EXTERNAL_FORM_LINK);
  };

  return (
    <>
      <SectionContainer alignCenter id={sectionIds.roadmap}>
        <AnimationOnScroll animateOnce duration={1.75} animateIn="animate__zoomIn">
          <SectionTitle>The Future</SectionTitle>
          <Content>
            {ContentItems.map((item) => (
              <Description key={uuidv4()} {...item} />
            ))}
          </Content>
        </AnimationOnScroll>
        <CtaContainer>
          <RoundedTitle onClick={openForm}>
            <h2>
              More Than Just Athletes - Join Us In
              <br />
              <strong>Tokenizing The Movement</strong>
            </h2>
          </RoundedTitle>
        </CtaContainer>
      </SectionContainer>
    </>
  );
};

Future.propTypes = {};

export default Future;
