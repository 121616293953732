import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './assets/styles/GlobalStyle';
import theme from './assets/styles/theme/theme';
import Home from './assets/pages/Home';
import 'animate.css/animate.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './assets/pages/About';
import Team from './assets/pages/Team';
import Footer from './assets/components/Footer';
import Header from './assets/components/Header';
import ScrollToTopRouter from './assets/components/shared/ScrollToTopRouter';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/governance" element={<Team />} />
          <Route path="/:section" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
        <ScrollToTopRouter />
      </BrowserRouter>
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
