import styledComponents from 'styled-components';
import { css } from 'styled-components';

const HorizontalLine = styledComponents.div`
    background-color: ${({ theme }) => theme.colors.common.primary};
    border-radius: 19px;
    height: 4px;
    width: 100%;

    ${({ marginBottom }) =>
      marginBottom &&
      css`
        margin-bottom: ${marginBottom};
      `};
    ${({ marginTop }) =>
      marginTop &&
      css`
        margin-top: ${marginTop};
      `};
      ${({ maxWidth }) =>
        maxWidth &&
        css`
          max-width: ${maxWidth};
        `};
`;

export default HorizontalLine;
