import React from 'react';
import PropTypes from 'prop-types';
import styledComponents from 'styled-components';
import { css } from 'styled-components';

const getButtonStyles = (size) => {
  switch (size) {
    case 'sm':
      return css`
        padding: 1rem 1.75rem;
        font-size: 1.5rem;
        line-height: 1.2;
      `;

    default:
      break;
  }
};

const ButtonStyled = styledComponents.button`
    display: flex;
    align-items: center;
    position: relative;
    background: 
    linear-gradient(${({ theme }) => theme.colors.common.black}, ${({ theme }) =>
  theme.colors.common.black}) padding-box,
    ${({ theme }) => theme.colors.button.border} border-box;
    border-radius: 55px;
    border: 2px solid transparent;
    line-height: 1.2;
    ${({ size }) => getButtonStyles(size)}


    &:hover {
        background: ${({ theme }) => theme.colors.button.active.background};

        > span {
            background: ${({ theme }) => theme.colors.button.active.text};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
`;

const ButtonLabel = styledComponents.span`
    font-size: 1.5rem;
    line-height: 1.2;
    background: ${({ theme }) => theme.colors.button.border};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 100;
`;

const Button = ({ size, onClick, label, type = 'button' }) => {
  return (
    <ButtonStyled size={size} onClick={onClick} type={type}>
      <ButtonLabel>{label}</ButtonLabel>
    </ButtonStyled>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
};

export default Button;
