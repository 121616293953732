import React from 'react';
import PropTypes from 'prop-types';
import Description from './Description';
import styledComponents from 'styled-components';

const Container = styledComponents.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
  max-width: 64rem;

  &:hover {
    svg,
    strong {
      color: ${({ theme }) => theme.colors.common.primary};
    }

    > div {
      border-color: ${({ theme }) => theme.colors.common.primary};
    }
  }
`;

const IconContainer = styledComponents.div`
  svg {
    height: 4.875rem;
    margin-bottom: 2rem;
    transition: color 0.25s;
  }
`;

const RoundedContainer = styledComponents.div`
  background: black;
  border: 3px solid ${({ theme }) => theme.colors.common.white};
  border-radius: 50px;
  padding: 2.5rem 1.375rem;
  text-align: center;
  transition: border-color 0.25s;
`;

const RoundedContainerWithIcon = ({ icon, label, description }) => {
  return (
    <Container>
      <IconContainer>{icon}</IconContainer>
      <RoundedContainer>
        <Description label={label} description={description} />
      </RoundedContainer>
    </Container>
  );
};

RoundedContainerWithIcon.propTypes = {
  icon: PropTypes.shape({}),
  label: PropTypes.string,
  description: PropTypes.string
};

export default RoundedContainerWithIcon;
