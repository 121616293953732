import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TransactionContext } from '../context/TransactionContext';
import Button from './shared/Button';

const WalletButton = ({ title, callback }) => {
  const { isMetaMaskInstalled, currentAccount, connectWallet } = useContext(TransactionContext);

  const openInNewTab = (pageUrl) => {
    window.open(pageUrl, '_blank');
  };

  const printAddress = (address) => {
    if (!address) {
      return '';
    }
    return `${address.slice(0, 5)}...${address.slice(-4)}`;
  };

  if (!isMetaMaskInstalled) {
    return (
      <Button
        onClick={() => openInNewTab('https://metamask.io/')}
        size="sm"
        label="Connect Wallet"
      />
    );
  }

  if (currentAccount) {
    return (
      <Button
        onClick={() => openInNewTab(`https://etherscan.io/address/${currentAccount}`)}
        size="sm"
        label={printAddress(currentAccount)}
      />
    );
  }

  return <Button onClick={() => connectWallet(callback)} size="sm" label={title} />;
};

WalletButton.propTypes = {
  title: PropTypes.string,
  callback: PropTypes.func
};

export default WalletButton;
