import styledComponents from 'styled-components';
import { css } from 'styled-components';

const Section = styledComponents.section`
    max-width: ${({ theme }) => theme.section.width};
    padding: 0 1.25rem;
    margin: 0 auto;
    width: 100%;

    ${({ alignCenter }) =>
      alignCenter &&
      css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
`;

export default Section;
