import React from 'react';
import Section from './shared/Section';
import SectionTitle from './shared/SectionTitle';
import styledComponents from 'styled-components';
import Description from './shared/Description';
import { sectionIds } from '../constants/constants';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as GlobalIcon } from '../images/icons/global.svg';
import { ReactComponent as LocalIcon } from '../images/icons/local.svg';
import PropTypes from 'prop-types';

const ContentItems = [
  {
    description: (
      <span>
        IAMN DAO includes an <strong>All Access</strong> membership tier for professional athletes
        exclusively and a <strong>Selective Access tier</strong> for contributors of the IAMN
        ecosystem.
      </span>
    )
  },
  {
    label: 'All Access:',
    description:
      'IAMN Citizenship is accessible exclusively to professional athletes. This tier grants member athletes full access to platform opportunities, events, networks, collaborations, and more.'
  },
  {
    label: 'Selective Access',
    description: (
      <>
        <i>Friends of the Nation</i> is an invite-only tier that allows contributors made up of
        investors, entrepreneurs, influencers, corporate sponsors, brands, non-profits, and
        institutions to join the IAMN platform and access its exclusive network.
      </>
    )
  },
  {
    description: (
      <>
        The <strong>Selective Access</strong> tier offers contributors two options based on token
        distribution:{' '}
      </>
    )
  },
  {
    label: 'Selective Global Access',
    icon: <GlobalIcon />,
    description: (
      <>
        Friends of the Nation - Access to both the IAMN ecosystem and the IAMN Online Platform,
        featuring content, event invitations, early peeks into new IAMN programs, products, NFT
        drops, and the chance to build and collaborate with our athletes.
      </>
    )
  },
  {
    label: 'Selective Local Access',
    icon: <LocalIcon />,
    description: (
      <>
        Friends of the Nation - Access to newsletters, a selection of local events, and city-focused
        IAMN DAO Platform channels.
      </>
    )
  }
];

const Container = styledComponents(Section)`
  margin-bottom: 10.75rem;
`;

const Content = styledComponents.div`
  display: grid;
  grid-gap: 2.5rem;
`;

const MembershipTiers = () => {
  return (
    <>
      <Container id={sectionIds.governance}>
        <SectionTitle>Membership Tiers</SectionTitle>

        <Content>
          {ContentItems.map((item) => (
            <Description key={uuidv4()} {...item} />
          ))}
        </Content>
      </Container>
    </>
  );
};

MembershipTiers.propTypes = {
  loadUserInfo: PropTypes.func,
  userInfo: PropTypes.shape({})
};

export default MembershipTiers;
