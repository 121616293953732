import React, { useState } from 'react';
import styledComponents from 'styled-components';
import useWindowSize from '../hooks/useWindowSize';
import Logo from './shared/Logo';
import { ReactComponent as HamburgerMenuIcon } from '../images/icons/hamburger-menu.svg';
import { ReactComponent as CloseIcon } from '../images/icons/close.svg';
import Section from './shared/Section';
import { EXTERNAL_FORM_LINK, sectionIds } from '../constants/constants';
import { v4 as uuidv4 } from 'uuid';
import WalletButton from './WalletButton';
import { useLocation, useNavigate } from 'react-router-dom';

const HeaderContainer = styledComponents.header`
  position: sticky;
  background-color: ${({ theme }) => theme.navigation.colors.background};
  top: 0;
  left: 0;
  right: 0;
  padding: 8px 0;
  z-index: 2;
`;

const HeaderContentContainer = styledComponents(Section)`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.navigation.colors.background}; 
`;

const HeaderNavigation = styledComponents.nav`
  display: flex;
  margin-left: auto;
  align-items: center;

  > :not(:first-child) {
      margin-left: 3rem;
  }

  > :last-child {
      margin-left: 2rem;
  }

  @media only screen and (max-width: 1280px) {
    width: 100%;
    flex-direction: column;
    padding-bottom: 0.75rem;
    background-color: ${({ theme }) => theme.navigation.colors.background};
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    > :not(:last-child) {
      margin-bottom: 0.75rem;
    }

    > :not(:first-child) {
      margin-left: 0;
    }

    > :last-child {
        margin-left: 0;
    }
  }
`;

const NavigationItems = styledComponents.a`
  font-size: 1.5rem;
  line-height: 1.2;

  &:hover {
    color: ${({ theme }) => theme.colors.typography.primary}
  }
`;

const HamburgerMenu = styledComponents.button`
  margin-left: auto;
  background-color: transparent;
  
  svg {
    width: 2rem;
    color: ${({ theme }) => theme.colors.common.white};
  }
`;

const Header = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 1280;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isHomePage = pathname === '/';

  const navigationItems = [
    {
      label: 'Our Mission',
      id: isHomePage && sectionIds.mission,
      page: !isHomePage && `/${sectionIds.mission.replace('#', '')}`
    },
    { label: 'About', page: '/about' },
    { label: 'Governance', page: '/governance' },
    {
      label: 'FAQ',
      id: isHomePage && sectionIds.faq,
      page: !isHomePage && `/${sectionIds.faq.replace('#', '')}`
    },
    { label: 'Join', externalUrl: EXTERNAL_FORM_LINK }
  ];

  const showMenu = () => {
    if (!isMobile) {
      return true;
    }

    if (isMenuOpen) {
      return true;
    }

    return false;
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }

    if (isMenuOpen && isMenuOpen) {
      setIsMenuOpen(false);
    }

    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: 'smooth'
    });
  };

  return (
    <HeaderContainer>
      <HeaderContentContainer>
        <Logo />
        {isMobile && (
          <HamburgerMenu onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {showMenu() ? <CloseIcon /> : <HamburgerMenuIcon />}
          </HamburgerMenu>
        )}
        {showMenu() && (
          <HeaderNavigation>
            {navigationItems.map(({ label, id, page, externalUrl }) => (
              <NavigationItems
                onClick={() => {
                  if (!id && externalUrl) {
                    window.open(EXTERNAL_FORM_LINK);
                  }
                  if (!id && page) {
                    navigate(page);
                  }
                  scrollToElement(id);
                }}
                key={uuidv4()}>
                {label}
              </NavigationItems>
            ))}
            <WalletButton title="Connect Wallet" />
          </HeaderNavigation>
        )}
      </HeaderContentContainer>
    </HeaderContainer>
  );
};

export default Header;
