import { createGlobalStyle } from 'styled-components';
import BackgroundImage from '../images/background.png';
import GothamBook from '../fonts/GothamBook.woff2';

const fontFamily = 'GothamBook';

export const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: ${fontFamily};
  src: url(${GothamBook}) format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-anchor: none;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

html {
  font-size: 16px;
  font-family: ${fontFamily};
  color: ${({ theme }) => theme.colors.typography.white};

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 12px;
  }

}

html,
body,
#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex: 1 0 auto;
}

*,
body,
button,
input,
textarea,
select {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}


svg {
  flex-shrink: 0;
}

textarea,
input:matches([type='email'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='url']) {
  -webkit-appearance: none;

  &::-webkit-autofill,
  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    user-select: none;
    display: none !important;
    position: absolute;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;

  &::-webkit-autofill,
  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    user-select: none;
    display: none !important;
    position: absolute;
  }
}


input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}


p {
  vertical-align: middle;
  word-break: break-word;
  line-height: 1.5;
  font-size: 1.5rem;
  letter-spacing: 0.5px;

  &,
  a,
  span {
    text-shadow: 3px 3px #000;

  }
}

span,
a {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

a,
button {
    cursor: pointer;
    outline: none;
    border: none;
    letter-spacing: 0.5px;
}

a {
  color: inherit;
  text-decoration: none;
}

strong {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  font-weight: 100;
  color: ${({ theme }) => theme.colors.common.primary};
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
}

h1 {
  font-size: 5.3rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem; 
}
`;
