import { colors } from './colors';

const theme = {
  section: {
    width: '1420px'
  },
  navigation: {
    colors: {
      background: colors.black
    }
  },
  colors: {
    common: {
      black: colors.black,
      white: colors.white,
      primary: colors.primary,
      danger: colors.danger
    },
    modal: {
      border: colors.primary,
      background: colors.modalBackground,
      text: colors.white
    },
    input: {
      border: colors.primary,
      color: colors.white
    },
    typography: {
      black: colors.black,
      white: colors.white,
      primary: colors.primary,
      linearGradient: colors.primaryLinearGradient
    },
    button: {
      border: colors.primaryLinearGradient,
      normal: {
        text: colors.primaryLinearGradient,
        background: colors.black
      },
      active: {
        text: colors.black,
        background: colors.primaryLinearGradient
      }
    }
  }
};

export default theme;
