import React from 'react';
import PropTypes from 'prop-types';
import styledComponents from 'styled-components';

const Container = styledComponents.div`
  display: flex;
  width: 100%;
  text-align: center;

  &:hover {
    strong {
      color: ${({ theme }) => theme.colors.common.primary};
    }
  }
  
  svg {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
  }

  strong {
    transition: color 0.25s;
  }
`;

const Description = ({ label, description, icon }) => {
  return (
    <Container>
      {icon && icon}
      <p>
        {label && (
          <>
            <strong>{label}</strong> -{' '}
          </>
        )}
        {description}
      </p>
    </Container>
  );
};

Description.propTypes = {
  label: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  icon: PropTypes.shape({})
};

export default Description;
