import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { METAMASK_PROVIDER_USER_REJECTED_REQUEST_ERROR_CODE } from '../constants/constants';

export const TransactionContext = React.createContext();

const { ethereum } = window;
const isMetaMaskInstalled = ethereum && ethereum.isMetaMask;

export const TransactionProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState('');

  const checkIfWalletIsConnected = async () => {
    try {
      const accounts = await ethereum.request({ method: 'eth_accounts' });
      if (accounts.length) {
        setCurrentAccount(accounts[0]);
      }
    } catch (error) {
      console.log(error);
      throw new Error('No ethereum object.');
    }
  };

  const connectWallet = async (callback) => {
    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      setCurrentAccount(accounts[0]);
      callback && callback();
    } catch (error) {
      if (error.code !== METAMASK_PROVIDER_USER_REJECTED_REQUEST_ERROR_CODE) {
        throw new Error('No ethereum object.');
      }
    }
  };

  const handleAccountChange = (accounts) => {
    accounts && accounts.length ? setCurrentAccount(accounts[0]) : setCurrentAccount('');
  };

  const registerEventListeners = () => {
    ethereum.on('accountsChanged', handleAccountChange);
  };

  const removeEventListeners = () => {
    ethereum.remove('accountsChanged');
  };

  useEffect(() => {
    if (!isMetaMaskInstalled) {
      return;
    }
    checkIfWalletIsConnected();
    registerEventListeners();

    return () => {
      removeEventListeners();
    };
  }, []);

  return (
    <TransactionContext.Provider
      value={{
        isMetaMaskInstalled,
        currentAccount,
        connectWallet
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

TransactionProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
