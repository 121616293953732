import styledComponents from 'styled-components';
import { css } from 'styled-components';

const Paragraph = styledComponents.p`
text-align: center;

${({ marginBottom }) =>
  marginBottom &&
  css`
    margin-bottom: ${marginBottom};
  `};
${({ marginTop }) =>
  marginTop &&
  css`
    margin-top: ${marginTop};
  `};
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `};
`;

export default Paragraph;
