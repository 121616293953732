import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HorizontalLine from './HorizontalLine';
import styledComponents from 'styled-components';
import { ReactComponent as ChevronDownIcon } from '../../images/icons/chevron.svg';
import { css } from 'styled-components';
import AnimateHeight from 'react-animate-height';

const Container = styledComponents.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
`;

const HeaderContainer = styledComponents.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Label = styledComponents.p`
  margin-right: auto;
  font-weight: 600;
`;

const DropdownButton = styledComponents.button`
  background: ${({ theme }) => theme.colors.button.active.background};
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  margin-left: 2rem;

  svg {
      height: 0.75rem;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      svg {
        transform: scale(-1);
      }
    `}
`;

const Content = styledComponents.p`
padding: 15px 0;
`;

const ExpandedContent = ({ label, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <HeaderContainer>
        <Label>{label}</Label>
        <DropdownButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <ChevronDownIcon />
        </DropdownButton>
      </HeaderContainer>
      <AnimateHeight duration={500} height={isOpen ? 'auto' : 0}>
        <Content>{description}</Content>
      </AnimateHeight>
      <HorizontalLine maxWidth={'8rem'} marginTop={'2rem'} />
    </Container>
  );
};

ExpandedContent.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string
};

export default ExpandedContent;
