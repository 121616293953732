import React from 'react';
import styledComponents from 'styled-components';
import Section from './shared/Section';
import pdf from '../documents/whitepaper.pdf';

const FooterContainer = styledComponents(Section)`
    display: flex;
    justify-content: space-between;
    padding-bottom: 6.25rem;
    max-width: 54.5rem;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      max-width: 100%;
      align-items: center;

      > :not(:last-child) {
        margin-bottom: 1rem;
      }
    }
`;

const Link = styledComponents.a`
  padding-bottom: 4px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.typography.primary};
  font-size: 1.5rem;  

  &:hover {
    color: ${({ theme }) => theme.colors.typography.primary};
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Link href="https://twitter.com/iamnation" target="_blank" rel="noopener noreferrer">
        Twitter
      </Link>
      <Link href="https://instagram.com/iamnation" target="_blank" rel="noopener noreferrer">
        Instagram
      </Link>
      <Link href="http://www.iam-nation.com/" target="_blank" rel="noopener noreferrer">
        I AM Nation
      </Link>
      <Link href={pdf} target="_blank" rel="noopener noreferrer">
        Whitepaper
      </Link>
    </FooterContainer>
  );
};

Footer.propTypes = {};

export default Footer;
