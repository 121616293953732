import React from 'react';
import styledComponents from 'styled-components';
import Section from '../components/shared/Section';
import SectionTitle from '../components/shared/SectionTitle';
import Paragraph from '../components/shared/Paragraph';
import { sectionIds } from '../constants/constants';
import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const SectionContainer = styledComponents(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 54.5rem;
  padding-top: 10.75rem;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
`;

const ContentParagraph = styledComponents(Paragraph)`
  margin-bottom: 2.5rem;
`;

const About = () => {
  return (
    <>
      <SectionContainer id={sectionIds.about}>
        <AnimationOnScroll animateOnce duration={1} animateIn="animate__fadeIn">
          <SectionTitle>About I AM Nation DAO</SectionTitle>
          <ContentParagraph>
            I AM Nation DAO is an exclusive community of professional athletes and contributors with
            a shared vision that together we are more capable, more influential, and more impactful.
          </ContentParagraph>
          <ContentParagraph>
            IAMN DAO combines real-world and innovative Web3 applications. Driven by the power of
            group economics, networking, and joint initiatives, our members work toward common
            goals.
          </ContentParagraph>
          <Paragraph marginBottom={'10.75rem'}>
            We are a community of professional athletes with ambitions beyond the game. We are ready
            to break barriers, collaborate, and instigate change.
          </Paragraph>
        </AnimationOnScroll>
      </SectionContainer>
    </>
  );
};

About.propTypes = {
  loadUserInfo: PropTypes.func,
  userInfo: PropTypes.shape({})
};

export default About;
