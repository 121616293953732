import React from 'react';
import Section from './shared/Section';
import SectionTitle from './shared/SectionTitle';
import styledComponents from 'styled-components';
import Description from './shared/Description';
import { sectionIds } from '../constants/constants';
import { v4 as uuidv4 } from 'uuid';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const ContentItems = [
  {
    description:
      'IAMN DAO is a collaborative space where members can contribute to common goals, share in decision-making, and earn rewards.'
  },
  {
    description:
      "As athletes, it is important for us to have ownership and control. The sporting world doesn't always facilitate this, so it is vital for IAMN to unite members under an organization that is 100% member-owned, lacking any central governing bodies. For too long we’ve been the “product,” now is the time to take control of our future."
  },
  {
    description:
      'Our framework increases transparency and security, and offers fully democratized voting structures on the blockchain.'
  },
  {
    description:
      'Our governing framework includes Founding Members, Team Leads & Contributors, working collectively to unite members and grow the DAO.'
  }
];

const Container = styledComponents(Section)`
  margin-bottom: 10.75rem;
  margin-top: 10.75rem;
`;

const Content = styledComponents.div`
  display: grid;
  grid-gap: 2.5rem;
`;

const Governance = () => {
  return (
    <Container id={sectionIds.governance}>
      <AnimationOnScroll animateOnce duration={1.75} animateIn="animate__zoomIn">
        <SectionTitle>Governance Structure</SectionTitle>
        <Content>
          {ContentItems.map((item) => (
            <Description key={uuidv4()} {...item} />
          ))}
        </Content>
      </AnimationOnScroll>
    </Container>
  );
};

Governance.propTypes = {};

export default Governance;
