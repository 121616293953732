import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { sectionIds } from '../../constants/constants';

const ScrollToTopRouter = () => {
  const { pathname } = useLocation();

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }

    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (pathname === '/mission') {
      scrollToElement(sectionIds.mission);
      return;
    }

    if (pathname === '/faq') {
      scrollToElement(sectionIds.faq);
      return;
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTopRouter;
