export const sectionIds = {
  about: '#about',
  governance: '#governance',
  roadmap: '#roadmap',
  faq: '#faq',
  token: '#token',
  community: '#community',
  mission: '#mission'
};

export const METAMASK_PROVIDER_USER_REJECTED_REQUEST_ERROR_CODE = 4001;

export const EXTERNAL_FORM_LINK = 'https://forms.gle/mhXmUh4x7YwV4Ddo6';
