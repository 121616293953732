import React from 'react';
import Governance from '../components/Governance';
import MembershipTiers from '../components/MembershipTiers';

const Team = () => {
  return (
    <>
      <Governance />
      <MembershipTiers />
    </>
  );
};

export default Team;
