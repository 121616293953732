import React from 'react';
import PropTypes from 'prop-types';
import styledComponents from 'styled-components';

const LoaderWrap = styledComponents.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.3);
`;

const Loader = styledComponents.div`
  display: block;
  width: 70px;
  height: 70px;
  border: 7px solid rgba(249,206,66,0.2);
  border-radius: 50%;
  border-top-color: ${({ theme }) => theme.colors.common.primary};
  animation: spin 1s linear infinite;


  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

const ContainerLoader = ({ children, isLoading }) => {
  if (isLoading) {
    return (
      <>
        {children}
        <LoaderWrap>
          <Loader />
        </LoaderWrap>
      </>
    );
  }

  return <>{children}</>;
};

ContainerLoader.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node
};

export default ContainerLoader;
