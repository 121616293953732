import React from 'react';
import styledComponents from 'styled-components';
import { sectionIds } from '../constants/constants';
import ExpandedContent from './shared/ExpandedContent';
import Section from './shared/Section';
import SectionTitle from './shared/SectionTitle';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

const ContentItems = [
  {
    label: 'Do I need to be an athlete to join?',
    description:
      'Professional athletes are invited to become members, as are individuals who add value to the community. Applications for DAO membership will be carefully weighed and considered by our founding members. Invitations will be made to contributors based on select criteria.'
  },
  {
    label: 'How do I know if I have been accepted?',
    description:
      'The IAMN team will send you an email including an acceptance letter and further information on how to join the DAO.'
  },
  {
    label: 'What is a DAO?',
    description:
      'A DAO or decentralized autonomous organization is a member-owned community that lacks a central governing body, characterized by transparency and a built-in treasury that can only be accessed with community approval.'
  }
];

const ContentContainer = styledComponents.div`
  max-width: 35rem;
  margin: 0 auto 10.75rem;
`;

const Faq = () => {
  return (
    <Section id={sectionIds.faq}>
      <SectionTitle>FAQ</SectionTitle>
      <ContentContainer>
        {ContentItems.map((item) => (
          <ExpandedContent key={uuidv4()} {...item} />
        ))}
      </ContentContainer>
    </Section>
  );
};

Faq.propTypes = {
  loadUserInfo: PropTypes.func,
  userInfo: PropTypes.shape({})
};

export default Faq;
