import React from 'react';
import { useNavigate } from 'react-router-dom';
import styledComponents from 'styled-components';
import LogoImage from '../../images/logo.png';

const LogoStyled = styledComponents.img`
    height: 3.75rem;
    width: auto;
    cursor: pointer;
`;

const Logo = () => {
  const navigate = useNavigate();

  return <LogoStyled onClick={() => navigate('/')} src={LogoImage} alt="Nation Dao Logo" />;
};

export default Logo;
