import React from 'react';
import styledComponents from 'styled-components';
import PropTypes from 'prop-types';
import Section from './shared/Section';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const BannerContainer = styledComponents(Section)`
    padding-top: 14rem;
    padding-bottom: 14rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const BannerDescription = styledComponents.p`
    font-size: 2rem;
    max-width: 38rem;
    margin: 2rem auto 0;
    text-align: center;
`;

const Title = styledComponents.h1`
  text-align: center;
`;

const Banner = ({ title, description }) => {
  return (
    <BannerContainer>
      <AnimationOnScroll animateOnce delay={0.5} duration={2} animateIn="animate__fadeIn">
        <Title>{title}</Title>
        <BannerDescription>{description}</BannerDescription>
      </AnimationOnScroll>
    </BannerContainer>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default Banner;
