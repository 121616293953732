import React from 'react';
import { sectionIds } from '../constants/constants';
import SectionTitle from './shared/SectionTitle';
import styledComponents from 'styled-components';
import Section from './shared/Section';
import { css } from 'styled-components';
import { ReactComponent as ArrowUp } from '../images/icons/arrow-up.svg';
import Paragraph from './shared/Paragraph';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { ReactComponent as Capital } from '../images/icons/capital.svg';
import { ReactComponent as Networking } from '../images/icons/networking.svg';
import { ReactComponent as SocialCause } from '../images/icons/social-cause.svg';
import RoundedContainerWithIcon from './shared/RoundedContainerWithIcon';
import HorizontalLine from './shared/HorizontalLine';
import { v4 as uuidv4 } from 'uuid';

const aboutUsItems = [
  {
    icon: <Capital />,
    label: 'CAPITAL',
    description:
      'We believe in the power of group economics. Pooling capital provides greater access to high-quality investment opportunities. Capital allows for investment into varying ventures, diversification, increased charitable contributions, and for the fulfillment of a host of other passions.'
  },
  {
    icon: <Networking />,
    label: 'COMMUNITY & NETWORKING',
    description:
      'Collectively we can accomplish more. Yet, building a strong community requires networking. Access to our exclusive network will open doors, introducing members to up-and-coming investment opportunities, and community initiatives, and connecting athletes with industry leaders.'
  },
  {
    icon: <SocialCause />,
    label: 'INITIATIVES & SOCIAL CAUSES',
    description:
      'Initiatives and social causes are integral components of who we are. Spearheading initiatives, utilizing member platforms to bring attention to social causes, raising funds, pooling charitable contributions, and working toward improving communities is what matters most.'
  }
];

const SectionContainer = styledComponents(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 54.5rem;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
`;

const ArrowUpIcon = styledComponents(ArrowUp)`
  height: 5.5rem;
  display: flex;
  margin: 0 auto 1rem;
  transition: color 0.25s;


  &:hover {
    color: ${({ theme }) => theme.colors.common.primary};
  }
`;

const ContentParagraph = styledComponents(Paragraph)`
  margin-bottom: 2.5rem;
`;

const Mission = () => {
  return (
    <>
      <SectionContainer id={sectionIds.mission}>
        <SectionTitle>Our Mission</SectionTitle>
        <ContentParagraph>
          Establishing a pro athlete network is key to initiating change and leveraging the
          knowledge, capital, and influence that each player and contributor brings to the
          community.
        </ContentParagraph>
        <ContentParagraph>
          IAMN DAO will organize professional athletes, focusing on key aspects critical to the
          personal and collective success of its members and their communities.{' '}
        </ContentParagraph>
        <Paragraph marginBottom={'10.75rem'}>
          As professional athletes, we have yet to come together to actualize our full potential as
          a community — until now. Collectively, we will make a bigger impact for our members and
          our communities.
        </Paragraph>
      </SectionContainer>

      <SectionContainer>
        <AnimationOnScroll animateOnce duration={1} animateIn="animate__fadeIn">
          <SectionTitle>A Community-Driven DAO</SectionTitle>
          {aboutUsItems.map((item) => (
            <RoundedContainerWithIcon key={uuidv4()} {...item} />
          ))}
          <ArrowUpIcon />
          <Paragraph>
            IAMN DAO aims to ensure the success of its members and their communities.
            <br />
            Opportunity calls — we’re ready.
          </Paragraph>
          <HorizontalLine marginBottom={'10.75rem'} marginTop={'2rem'} />
        </AnimationOnScroll>
      </SectionContainer>
    </>
  );
};

export default Mission;
