import React from 'react';
import Banner from '../components/Banner';
import Faq from '../components/Faq';
import Future from '../components/Future';
import Mission from '../components/Mission';
import ContainerLoader from '../components/shared/ContainerLoader';

const Home = () => {
  return (
    <>
      <Banner
        title="I AM Nation DAO"
        description="A community of professional athletes who are spearheading Web3 transformation"
      />
      <Mission />
      <Future />
      <Faq />
      <ContainerLoader />
    </>
  );
};

export default Home;
